// api.js
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const generateWeights = async (query) => {
  try {
    const response = await api.post('/generate_weights', { query });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'An error occurred while generating weights';
  }
};

export const searchProjects = async (query, weights = null) => {
  try {
    const requestBody = {
      query,
      ...(weights && { weights }) // Only include weights if they exist
    };
    
    const response = await api.post('/search', requestBody);
    console.log('response')
    console.log(response)
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'An error occurred while searching';
  }
};

export const processResume = async (file, githubUsername) => {
  try {
    const formData = new FormData();
    formData.append('resume', file);
    formData.append('github_username', githubUsername);
    
    const response = await api.post('/process_resume', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response?.data?.error || 'An error occurred while processing the resume';
  }
};

export default api;