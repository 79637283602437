import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from './api';

function customSigmoid(x, steepness = 5) {
  // Shift and scale x to be centered around 0
  const xScaled = (x - 0.3) * 2;
  // Apply sigmoid transformation
  return 1 / (1 + Math.exp(-steepness * xScaled));
}

export const generateWeights = createAsyncThunk(
  'search/generateWeights',
  async (query, { rejectWithValue }) => {
    try {
      console.log('hellloo')
      const response = await api.generateWeights(query);
      return response.weights;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const searchCandidates = createAsyncThunk(
  'search/searchCandidates',
  async ({ query, weights = null }, { rejectWithValue }) => {
    try {
      const response = await api.searchProjects(query, weights);
      
      // Map the results to match our component's expectations
      return {
        results: response.results.map((result, index) => ({
          rank: index + 1,
          name: result.person_name,
          score: Math.round(result.score * 100),
          match_reason: result.match_reason,
          // Map the projects array if it exists
          projects: Array.isArray(result.projects) ? result.projects.map(project => ({
            project_name: project.project_name || '',
            description: project.description || '',
            match_reason: project.match_reason || '',
            score: Math.round(project.score * 100) || 0
          })) : []
        })),
        key_experiences: response.key_experiences || []
      };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    candidates: [],
    key_experiences: [],
    weightsLoading: false,
    searchLoading: false,
    error: null
  },
  reducers: {
    clearResults: (state) => {
      state.candidates = [];
      state.key_experiences = [];
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateWeights.pending, (state) => {
        state.weightsLoading = true;
        state.error = null;
      })
      .addCase(generateWeights.fulfilled, (state, action) => {
        state.weightsLoading = false;
        state.key_experiences = action.payload;
      })
      .addCase(generateWeights.rejected, (state, action) => {
        state.weightsLoading = false;
        state.error = action.payload;
      })
      .addCase(searchCandidates.pending, (state) => {
        state.searchLoading = true;
        state.error = null;
      })
      .addCase(searchCandidates.fulfilled, (state, action) => {
        state.searchLoading = false;
        state.candidates = action.payload.results;
        state.key_experiences = action.payload.key_experiences;
      })
      .addCase(searchCandidates.rejected, (state, action) => {
        state.searchLoading = false;
        state.error = action.payload;
      });
  }
});

export const { clearResults } = searchSlice.actions;
export default searchSlice.reducer;