import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Slider, Box, Paper, TextField, Button, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';

const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: '800px',
  margin: '24px auto',
  background: '#ffffff',
  borderRadius: '16px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
  '&:hover': {
    boxShadow: '0 6px 8px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: '#3B6AC1',
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: '0 0 0 8px rgba(59, 106, 193, 0.16)',
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.32,
  },
}));

const WeightSliders = ({ experiences, onWeightsChange, onSearch, searchLoading }) => {
  const [weights, setWeights] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (experiences && experiences.length > 0) {
      setWeights(experiences.map(exp => ({
        description: exp[0],
        weight: Math.round(exp[1] * 100), // Convert to percentage and round to whole number
      })));
      setHasChanges(false);
    }
  }, [experiences]);

  const handleWeightChange = (index, newValue) => {
    const newWeights = [...weights];
    // For text input: only allow empty string or whole numbers
    if (typeof newValue === 'string') {
      // Remove any decimal points and convert to integer
      const cleanValue = newValue.replace(/\./g, '');
      newWeights[index].weight = cleanValue === '' ? '' : parseInt(cleanValue) || 0;
    } else {
      // For slider: round to nearest integer
      newWeights[index].weight = Math.round(newValue);
    }
    setWeights(newWeights);
    setHasChanges(true);

    if (onWeightsChange) {
      // Convert back to decimals (0-1 range) for the parent component
      const normalizedWeights = newWeights.map(w => [
        w.description, 
        w.weight === '' ? 0 : w.weight / 100
      ]);
      onWeightsChange(normalizedWeights);
    }
  };

  const handleBlur = (index) => {
    const newWeights = [...weights];
    // If the field is empty on blur, set it to 0
    if (newWeights[index].weight === '') {
      newWeights[index].weight = 0;
      setWeights(newWeights);
      
      if (onWeightsChange) {
        const normalizedWeights = newWeights.map(w => [
          w.description, 
          w.weight / 100
        ]);
        onWeightsChange(normalizedWeights);
      }
    }
  };

  const totalWeight = weights.reduce((sum, w) => sum + (w.weight === '' ? 0 : w.weight), 0);
  const isValidTotal = totalWeight === 100;

  return (
    <StyledCard>
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 3 }}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              color: '#2B4C8C',
              fontFamily: '"Plus Jakarta Sans", sans-serif',
            }}
          >
            Ideal Candidate Criteria
          </Typography>
          
          {hasChanges && (
            <Typography
              sx={{
                fontSize: '0.875rem',
                color: '#3B6AC1',
                fontFamily: '"Plus Jakarta Sans", sans-serif',
                fontStyle: 'italic'
              }}
            >
              Changes pending
            </Typography>
          )}
        </Box>

        <Typography
          sx={{
            fontSize: '0.875rem',
            color: isValidTotal ? '#4A5568' : '#DC2626',
            fontFamily: '"Plus Jakarta Sans", sans-serif',
            mb: 2,
            textAlign: 'right',
          }}
        >
          Total Weight: {totalWeight}%
          {!isValidTotal && (
            <Typography
              component="span"
              sx={{
                fontSize: '0.75rem',
                color: '#DC2626',
                ml: 1,
              }}
            >
              (Must equal 100%)
            </Typography>
          )}
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {weights.map((weight, index) => (
            <Paper
              key={index}
              elevation={0}
              sx={{
                p: 2,
                backgroundColor: 'rgba(248, 250, 255, 0.8)',
                borderRadius: 2,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
                <Typography
                  sx={{
                    fontSize: '0.875rem',
                    color: '#4A5568',
                    fontFamily: '"Plus Jakarta Sans", sans-serif',
                    flex: 1,
                    pr: 2,
                  }}
                >
                  {weight.description}
                </Typography>
                <TextField
                  value={weight.weight}
                  onChange={(e) => handleWeightChange(index, e.target.value)}
                  onBlur={() => handleBlur(index)}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    inputProps: { 
                      min: 0, 
                      max: 100,
                      step: 1
                    },
                    endAdornment: <Typography>%</Typography>,
                  }}
                  type="number"
                  sx={{ width: '80px' }}
                />
              </Box>
              <StyledSlider
                value={weight.weight === '' ? 0 : weight.weight}
                onChange={(_, value) => handleWeightChange(index, value)}
                step={1}
                min={0}
                max={100}
                aria-label="Weight"
              />
            </Paper>
          ))}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button
            variant="contained"
            onClick={onSearch}
            disabled={searchLoading || !isValidTotal}
            startIcon={searchLoading ? <CircularProgress size={20} /> : <SearchIcon />}
            sx={{
              backgroundColor: '#3B6AC1',
              '&:hover': {
                backgroundColor: '#2B4C8C',
              },
              borderRadius: '12px',
              padding: '10px 24px',
              textTransform: 'none',
              fontFamily: '"Plus Jakarta Sans", sans-serif',
              fontWeight: 600,
            }}
          >
            IntraSearch
          </Button>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default WeightSliders;