import logo from './logo.svg';
import { Provider } from 'react-redux';
import { store } from './services/store';
import React from 'react';
import ResultsDisplay from './components/ResultsDisplay/ResultsDisplay';
import ResumeUpload from './components/ResumeUpload/ResumeUpload';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { Box, AppBar, Toolbar, Button } from '@mui/material';
import '@fontsource/montserrat'; // Import Montserrat font

const theme = createTheme({
  palette: {
    primary: {
      main: '#2C3E50', // Dark blue-grey
    },
    secondary: {
      main: '#2980B9', // Blue
    },
    success: {
      main: '#28a745', // Professional green
    },
    warning: {
      main: '#ffc107', // Professional yellow
    },
    error: {
      main: '#dc3545', // Professional red
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

const Navigation = () => (
  <AppBar position="static" color="transparent" elevation={0}>
    <Toolbar>
      <Box sx={{ flexGrow: 1, display: 'flex', gap: 2 }}>
        <Button
          component={Link}
          to="/"
          sx={{ 
            color: '#3B6AC1',
            '&:hover': {
              backgroundColor: 'rgba(59, 106, 193, 0.1)',
            },
            fontFamily: '"Plus Jakarta Sans", sans-serif',
            fontWeight: 600,
          }}
        >
          Search
        </Button>
        <Button
          component={Link}
          to="/upload"
          sx={{ 
            color: '#3B6AC1',
            '&:hover': {
              backgroundColor: 'rgba(59, 106, 193, 0.1)',
            },
            fontFamily: '"Plus Jakarta Sans", sans-serif',
            fontWeight: 600,
          }}
        >
          Upload Resume
        </Button>
      </Box>
    </Toolbar>
  </AppBar>
);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Navigation />
          <Routes>
            <Route path="/" element={<ResultsDisplay />} />
            <Route path="/upload" element={<ResumeUpload />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;



