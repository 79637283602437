import React, { useState } from 'react';
import {
  Typography,
  Box,
  Button,
  TextField,
  Paper,
  CircularProgress,
  Alert,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { motion } from 'framer-motion';
import '@fontsource/plus-jakarta-sans';
import { processResume } from '../../services/api';


const StyledContainer = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, #f8faff 0%, #f0f4fb 100%)',
  minHeight: '100vh',
  padding: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  overflowX: 'hidden', // Prevent horizontal scrolling
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '800px',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    padding: 0,
  },
}));

const StyledForm = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: '16px',
  backgroundColor: 'white',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05)',
  width: '100%',
  maxWidth: '600px',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    borderRadius: '12px',
    width: 'calc(100% - 32px)', // Account for container padding
    margin: '0 auto',
  },
}));

const GradientTitle = styled(Typography)(({ theme }) => ({
  fontSize: '96px',
  fontWeight: 800,
  background: 'linear-gradient(135deg, #6FA8DC 0%, #1A365D 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(1),
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  letterSpacing: '-2px',
  [theme.breakpoints.down('md')]: {
    fontSize: '72px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '42px',
    maxWidth: '100%',
  },
}));

const StyledCatchline = styled(Typography)(({ theme }) => ({
  fontSize: '28px',
  fontWeight: 500,
  background: 'linear-gradient(135deg, #4A90E2 0%, #1A365D 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  marginBottom: theme.spacing(6),
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  letterSpacing: '0.5px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    marginBottom: theme.spacing(3),
  },
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const UploadButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5),
      '& svg': {
        fontSize: '1.2rem', // Smaller icon for mobile
      },
    },
  },
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '8px',
  },
  backgroundColor: '#3B6AC1',
  '&:hover': {
    backgroundColor: '#2B4C8C',
  },
  textTransform: 'none',
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  fontWeight: 600,
  fontSize: '16px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '13px',
    lineHeight: 1.3,
  },
  whiteSpace: 'normal',
  height: 'auto',
  minHeight: '48px',
  [theme.breakpoints.down('sm')]: {
    minHeight: '36px',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: '12px 32px',
  [theme.breakpoints.down('sm')]: {
    padding: '8px 16px',
    marginTop: theme.spacing(2),
    minHeight: '36px',
    fontSize: '14px',
  },
  borderRadius: '12px',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '8px',
  },
  backgroundColor: '#3B6AC1',
  '&:hover': {
    backgroundColor: '#2B4C8C',
  },
  textTransform: 'none',
  fontFamily: '"Plus Jakarta Sans", sans-serif',
  fontWeight: 600,
}));

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '14px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
  },
  marginTop: theme.spacing(1),
}));


const ResumeUpload = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [file, setFile] = useState(null);
  const [githubUsername, setGithubUsername] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [uploadResults, setUploadResults] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && (selectedFile.type === 'application/pdf' || selectedFile.type === 'application/zip')) {
      setFile(selectedFile);
      // Clear GitHub username if switching to ZIP file
      if (selectedFile.type === 'application/zip') {
        setGithubUsername('');
      }
      setError(null);
    } else {
      setError('Please upload a PDF or ZIP file');
      setFile(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccess(false);
    setUploadResults(null);

    if (!file) {
      setError('Please select a file');
      return;
    }

    // Only require GitHub username for single PDF uploads
    if (file.type === 'application/pdf' && githubUsername.trim() !== '') {
      if (!githubUsername.match(/^[a-zA-Z0-9-]+$/)) {
        setError('Invalid GitHub username format');
        return;
      }
    }

    setLoading(true);
    try {
      const result = await processResume(file, githubUsername);
      
      if (file.type === 'application/zip') {
        setUploadResults(result);
        setSuccess(true);
      } else {
        setSuccess(true);
      }
      
      setFile(null);
      setGithubUsername('');
      document.getElementById('resume-file').value = '';
    } catch (err) {
      setError(err.message || 'Failed to upload file');
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContainer>
      <ContentWrapper>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <GradientTitle>Resume Upload</GradientTitle>
          <StyledCatchline>
            Add your profile to our talent pool
          </StyledCatchline>
        </motion.div>

        <StyledForm component="form" onSubmit={handleSubmit}>
          {file?.type === 'application/pdf' && (
            <TextField
              fullWidth
              label="GitHub Username (Optional)"
              variant="outlined"
              value={githubUsername}
              onChange={(e) => setGithubUsername(e.target.value)}
              sx={{ 
                mb: 3,
                '& .MuiInputLabel-root': {
                  fontSize: isMobile ? '14px' : '16px',
                },
                '& .MuiInputBase-input': {
                  fontSize: isMobile ? '14px' : '16px',
                  padding: isMobile ? '12px' : '16px',
                },
              }}
            />
          )}

          <HiddenInput
            accept=".pdf,.zip"
            id="resume-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="resume-file">
            <UploadButton
              variant="contained"
              component="span"
              fullWidth
              startIcon={<CloudUploadIcon />}
            >
              {file ? file.name : (
                isMobile ? 'Upload Resume (PDF/ZIP)' : 'Upload Single Resume (PDF) or Multiple Resumes (ZIP)'
              )}
            </UploadButton>
          </label>

          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                '& .MuiAlert-message': {
                  fontSize: isMobile ? '13px' : '14px',
                },
              }}
            >
              {error}
            </Alert>
          )}

          {success && !uploadResults && (
            <Alert 
              severity="success" 
              sx={{ 
                mb: 2,
                '& .MuiAlert-message': {
                  fontSize: isMobile ? '13px' : '14px',
                },
              }}
            >
              Resume uploaded successfully!
            </Alert>
          )}

          {uploadResults && (
            <Box sx={{ mt: 2, mb: 2 }}>
              <Alert 
                severity={uploadResults.failed_uploads > 0 ? "warning" : "success"}
                sx={{ 
                  mb: 2,
                  '& .MuiAlert-message': {
                    fontSize: isMobile ? '13px' : '14px',
                  },
                }}
              >
                {`Processed ${uploadResults.total_files} files: ${uploadResults.successful_uploads} successful, ${uploadResults.failed_uploads} failed`}
              </Alert>
              
              {uploadResults.failed_uploads > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Typography 
                    variant="subtitle2" 
                    color="error" 
                    sx={{ 
                      mb: 1,
                      fontSize: isMobile ? '13px' : '14px',
                    }}
                  >
                    Failed Uploads:
                  </Typography>
                  {uploadResults.errors.map((error, index) => (
                    <Typography 
                      key={index} 
                      variant="body2" 
                      color="error"
                      sx={{ 
                        fontSize: isMobile ? '12px' : '14px',
                        lineHeight: 1.5,
                      }}
                    >
                      {`${error.file_name}: ${error.error}`}
                    </Typography>
                  ))}
                </Box>
              )}
            </Box>
          )}

          <SubmitButton
            type="submit"
            variant="contained"
            disabled={loading}
            fullWidth
          >
            {loading ? (
              <CircularProgress size={isMobile ? 20 : 24} color="inherit" />
            ) : (
              'Submit'
            )}
          </SubmitButton>
        </StyledForm>
      </ContentWrapper>
    </StyledContainer>
  );
};

export default ResumeUpload;